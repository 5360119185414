






import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios'

@Component
export default class App extends Vue {
  isAuthenticated: boolean = false
  userInfo: any = null
  

  mounted() {
    axios.get(`https://server-ui.demo.octonica.com/user`).then((response) => {
      const data: any = response.data
      if(data.authState === "Authorized") {
        const userInfo = data.userInfoResponse
        this.isAuthenticated = true
        this.userInfo = userInfo
      }
      else {
        this.isAuthenticated = false
        this.userInfo = null
      }
    }).catch((reason) => {
      throw reason
    })
  }
}
