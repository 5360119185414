












import { Component, Vue, Prop } from 'vue-property-decorator';
import SignIn from '@/components/SignIn.vue';
import User from '@/components/User.vue';

@Component({
  components: {
    SignIn,
    User
  },
})
export default class Home extends Vue {
@Prop() readonly isAuthenticated!: boolean
@Prop() readonly userInfo!: any
}
