



































import { Component, Prop, Vue } from 'vue-property-decorator';
import axios, { AxiosResponse } from 'axios'

@Component
export default class User extends Vue {
  @Prop() readonly userInfo!: any
  apiStatus: string = ''

  onButtonClicked(): void {
    window.location.href = 'https://server-ui.demo.octonica.com/logout'
  }

  async mounted() {
    let apiStatus: string = ''
    axios.get(`https://server-ui.demo.octonica.com/call-macro`, { params: { macro: 'api_get_authorbook' } }).then(value => {
      console.log(value)
      apiStatus = 'Выполнено!'
    }).catch(reason => {
      console.log(reason)
      apiStatus = `Ошибка! ${reason}`
    }).finally(() => {
      this.apiStatus = apiStatus
    })
  }
}
